import { useReadCypher } from "use-neo4j";
import SankeyDiagram from "./SankeyDiagram";
import { SankyContext } from "./SankyProvider";
import React from "react";
import {
    sankeyLeft as d3sankeyLeft,
    sankeyRight as d3sankeyRight,
    sankeyCenter as d3sankeyCenter,
    sankeyJustify as d3sankeyJustify,
} from "d3-sankey";

export default function Sankey({ queryParams, dataResolver, cypherQueryResolver, sankeyDraw }:
    { queryParams: any, dataResolver: Function, cypherQueryResolver: Function, sankeyDraw: Function }) {

    const { direction, nodeLimit, length, aligned, colorBy } = React.useContext(SankyContext)

    const query = cypherQueryResolver({ direction: direction, nodeLimit: nodeLimit, length: length })

    const sankeyAligns = [
        d3sankeyLeft,
        d3sankeyRight,
        d3sankeyCenter,
        d3sankeyJustify,
    ];

    const edgeColors = ["input", "output", "path", "none"];

    const { loading, error, records, result, run } = useReadCypher(query, queryParams)

    React.useEffect(() => {
        run(queryParams)
    }, [query]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
    if (!records?.length) return <p>No data</p>;

    return (
        <>
            <SankeyDiagram reponse={dataResolver(records)} aligned={sankeyAligns[aligned]} colorBy={edgeColors[colorBy]} draw={sankeyDraw} />
        </>
    );
};