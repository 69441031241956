export function getCypherSankey(records: any) {
    const nodesIdx: any = {};
    const relationsIdx: any = {};
    
    records?.forEach(function (record: any) {

        const path = record.get('p');    
        const segments = path.segments;

        segments?.forEach((segment: any) => {
  
            const source = segment.start;
            const target = segment.end;
            const relationship = segment.relationship;

            nodesIdx[source.identity.low] = {
                ...source.properties,
                type: source?.labels[0],
                identity: source.identity.low,
            }

            nodesIdx[target.identity.low] = {
                ...target.properties,
                type: target?.labels[0],
                identity: target.identity.low
            }
            
            relationsIdx[relationship.identity.low] = {
                source: {
                    name: source.properties.name,
                    id: source.properties.id,
                    type: source?.labels[0],
                    identity: source.identity.low
                },
                target: {
                    name: target.properties.name,
                    id: target.properties.id,
                    type: target?.labels[0],
                    identity: target.identity.low
                },
                type: relationship.type,
                value: 1
            };
        });
    });
 

    return { 
        nodes: Object.values(nodesIdx), 
        links: Object.values(relationsIdx)
    };
}
