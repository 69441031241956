
import React from 'react';
import TableComponent from '../../Components/Table';
import Sankey from "../../Components/Sankey/SankeyComponent";
import SankeyFilter from '../../Components/Sankey/SankeyFilterComponent';

import { Grid, Container, Header, Table, Icon, Divider } from 'semantic-ui-react'
import { getTableProperties, convertTableFieldsQuery, convertTableFields as convertTableFields, convertTableSourcesQuery } from "../../Components/Table/RecordsFormatResolve";
import { getTableSourcesQuery, getTable, getTableSankey } from "../../db/Queries";
import { TableProperties, TableStyle } from '../../Components/Style';
import { SankyContext } from "../../Components/Sankey/SankyProvider";
import { getCypherSankey } from '../../Components/Sankey/SankeyResolve';
import { SankeyDraw } from '../../Components/Sankey/SankeyTableDraw';
import { useReadCypher } from "use-neo4j";
import { useParams } from "react-router-dom";

export default function TablesPage() {
    const { direction } = React.useContext(SankyContext)
    const params: any = useParams();
    const paramsList = params['*'].split('/');
    const idKey = paramsList.length - 1;
    const tableID = parseInt(paramsList[idKey] || '');
    const slugName = (paramsList.splice(0, idKey) || '').join(', ');
    const queryParams = { id: tableID }
    const query = getTable()

    const { loading, error, records } = useReadCypher(query, queryParams)
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
    if (!records?.length) return <p>No data</p>;

    const record: any = records[0];

    const tableProperties = getTableProperties(record)

    const fields = convertTableFields(records)

    const table = convertTableFieldsQuery(tableProperties)

    return (
        <SankeyFilter>
            <Container>
                <Header as='h2' icon inverted textAlign='center'>
                    <Icon name='table' />
                    {slugName} - {tableProperties.name}
                    <Header.Subheader>
                        {tableProperties.description}
                    </Header.Subheader>
                </Header>
                <Divider />
                <Grid columns='equal' divided='vertically'>
                    <Grid.Row columns='equal'>
                        <Grid.Column>
                            <Header inverted as='h2' textAlign='center'> Properties </Header>
                            <TableProperties columns={2} celled striped selectable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Key</Table.HeaderCell>
                                        <Table.HeaderCell>Value</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    <Table.Row key='name'>
                                        <Table.Cell>name</Table.Cell>
                                        <Table.Cell>{table.name}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row key='id'>
                                        <Table.Cell>id</Table.Cell>
                                        <Table.Cell>{table.id}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row key='version'>
                                        <Table.Cell>version</Table.Cell>
                                        <Table.Cell>{table.version}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row key='clazz'>
                                        <Table.Cell>clazz</Table.Cell>
                                        <Table.Cell>{table.clazz}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row key='labels'>
                                        <Table.Cell>labels</Table.Cell>
                                        <Table.Cell>{table.labels}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row key='created_by'>
                                        <Table.Cell>created_by</Table.Cell>
                                        <Table.Cell>{table.created_by}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row key='creation_time'>
                                        <Table.Cell>creation_time</Table.Cell>
                                        <Table.Cell>{table.creation_time ? new Date(table.creation_time)?.toLocaleString() : ''}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row key='modified_by'>
                                        <Table.Cell>modified_by</Table.Cell>
                                        <Table.Cell>{table.modified_by}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row key='modified_time'>
                                        <Table.Cell>modified_time</Table.Cell>
                                        <Table.Cell>{table.modified_time ? new Date(table.modified_time)?.toLocaleString() : ''}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row key='input_interval'>
                                        <Table.Cell>input_interval</Table.Cell>
                                        <Table.Cell>{table.input_interval}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row key='output_interval'>
                                        <Table.Cell>output_interval</Table.Cell>
                                        <Table.Cell>{table.output_interval}</Table.Cell>
                                    </Table.Row>


                                </Table.Body>
                            </TableProperties>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns='equal'>
                        <Grid.Column>
                            <Header inverted as='h2' textAlign='center'> Columns </Header>
                            <TableStyle celled striped selectable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Column</Table.HeaderCell>
                                        <Table.HeaderCell>Type</Table.HeaderCell>
                                        <Table.HeaderCell>Labels</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {fields?.map(({ link, column, type, labels, id }: { link: string, column: string, type: string, labels: string, id: string }) => {
                                        function handleRowClick({ link }: { link: string }) {
                                            window.open(link, '_blank');
                                        }
                                        return (
                                            <Table.Row key={id} onClick={() => handleRowClick({ link })} style={{ cursor: 'pointer' }}>
                                                <Table.Cell>{column}</Table.Cell>
                                                <Table.Cell>{type}</Table.Cell>
                                                <Table.Cell>{labels}</Table.Cell>
                                            </Table.Row>
                                        )
                                    })}
                                </Table.Body>
                            </TableStyle>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns='equal'>
                        <Grid.Column>
                            <Header as='h2' inverted textAlign='center'> {(direction ? 'Upstream' : 'Downstream')} </Header>
                            <TableComponent tableQuery={getTableSourcesQuery}
                                queryParams={queryParams}
                                headersNames={['Level', 'Source', 'Type', 'Delay', 'Time Wrap']}
                                resolver={convertTableSourcesQuery} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
            <Grid>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Header inverted as='h2' textAlign='center'>
                            Sankey Diagram
                        </Header>
                        <Divider clearing />
                        <Sankey cypherQueryResolver={getTableSankey} queryParams={queryParams} dataResolver={getCypherSankey} sankeyDraw={SankeyDraw} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </SankeyFilter>
    )
}
