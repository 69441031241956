import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { TableStyle } from '../Style';
import { useReadCypher } from "use-neo4j";
import { useNavigate } from "react-router-dom";
import { SankyContext, SankyProvider } from "../../Components/Sankey/SankyProvider";

export default function TableComponent({
    tableQuery,
    queryParams,
    headersNames,
    resolver
}: { tableQuery: Function, queryParams: Record<string, any>, headersNames: string[], resolver: Function }) {
    //const navigate = useNavigate();
    const { direction, nodeLimit, length, aligned, colorBy } = React.useContext(SankyContext)

    const query = tableQuery({ direction: direction, nodeLimit: nodeLimit, length: length });

    const { loading, error, records, result, cypher, params, run } = useReadCypher(query, queryParams)

    React.useEffect(() => {
        run(queryParams)
    }, [query]);


    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    const resolved = resolver(direction, records);

    return (
        <TableStyle celled striped selectable>
            <Table.Header>
                <Table.Row>
                    {headersNames.map(name => {
                        return (<Table.HeaderCell>{name}</Table.HeaderCell>)
                    })}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {resolved?.map((record: any) => {
                    function handleRowClick({ link }: { link: string }) {
                        window.open(link, '_blank');
                    }
                    return (
                        <>
                            <Table.Row key={record?.id} onClick={() => handleRowClick({ link: record?.link })} style={{ cursor: 'pointer' }}>
                                {Object.entries(record).map(([key, value]: [string, any], index: number) => {
                                    if (key.toLowerCase() != 'link') {
                                        return <Table.Cell>{value}</Table.Cell>
                                    }
                                })}
                            </Table.Row>
                        </>
                    )
                })}
            </Table.Body>
        </TableStyle>
    )
}