import styled from 'styled-components';
import { List, Table } from 'semantic-ui-react';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin: 4rem 1rem 4rem 1rem;
    `;

export const GridItem = styled.div`
  width: 48vw;
  height: 40vh;
  overflow-y: scroll;
  flex-direction: row;
    `;

export const TableStyle = styled(Table)` 
  &&& {
    width: 100%;
  }

  &&& td {
    padding-left:2%;
  }

  &&& tbody tr{
    height: 5vh;
    font-size: 18px;
  }
  
  &&&.table th {
    background-color: #ccdee4;
  }
   
  &&&.table thead th{
    font-size: 20px;
    text-align: center;
    color: grey;
    width: 6vw;
  }

  &&&.table thead tr:first-child > th {
    position: sticky !important;
    top: 0;
    z-index: 2;
  }  
`

export const TableProperties = styled(Table)` 
  &&& {
    width: 100%;
  }

  &&& td {
    padding-left:2%;
  }

  &&& td:first-child {
    text-align: left;
    font-size: 20px;
  }
  
  &&& tbody tr{
    font-size: 18px;
    height: 5vh;
   
  }
  
  &&&.table th {
    background-color: #ccdee4;
  }
  
  &&&.table thead th{
    font-size: 20px;
    text-align: center;
    color: grey;
    width: 6vw;
  }

  &&&.table thead tr:first-child > th {
    position: sticky !important;
    top: 0;
    z-index: 2;
  }  
`

export const TablePropertiesList = styled(List)` 
  font-weight: bold;
 
 

  &&& .item {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 200px;
    height: 100px;
  }

  &&& .header {
    font-size: 18px;
  }

  &&& .description {
    font-size: 16px;
  }
 
`;