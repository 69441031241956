import React, {useState, useCallback} from 'react';
import {Header} from "semantic-ui-react";
import {StyledDiv, StyledInput} from "./Style";
import SearchResults from "../../Components/SearchResults";
import {useLazyReadCypher} from "use-neo4j/dist/cypher";

export default function Search() {
    const [term, setTerm] = useState('');
    const query = `
    CALL db.index.fulltext.queryNodes("id_fulltext_idx", "${term}") 
    YIELD node as f, score
    with { identity: ID(f), labels: labels(f) , properties: { name: f.name} } as node, score
    return node, score
    union
    CALL db.index.fulltext.queryNodes("lineage_fulltext_idx", "${term}") 
    YIELD node as f, score
    with { identity: ID(f), labels: labels(f) , properties: { name: f.name} } as node, score
    return node, score
    order by score
`;
 
    const [runQuery, { loading, error, records }] = useLazyReadCypher(query);
   
    const handleClick = useCallback(() => {
        if (term) {
            runQuery();
        }
    }, [term]);

    function handleEnter(event: any) {
        if (event.keyCode === 13) {
            handleClick();
        }
    }

    return (
        <>
            <Header as='H2' color='blue' textAlign='center' block>Search</Header>
            <StyledDiv>
                <StyledInput
                    value={term}
                    onChange={(e: any) => setTerm(e.target.value)}
                    onKeyDown={(e: any) => handleEnter(e) }
                />
                <button onClick={handleClick}>Search</button>
            </StyledDiv>
            <div>
                <SearchResults headersNames={['Label', 'Name' ]} loading={loading} error={error} records={records}/>
            </div>
        </>
    );
}
