import React, { isValidElement } from 'react';

export function getTableProperties(record: any) {
    const path = record.get('p')
    return {
        ...path.start.properties,
        labels: path.start.labels
    }
}

export function getColumnPath(record: any) {
    const path = record.get('p');
    return path.segments.map((s: { start: any; end: any; relationship: any; }) => {
        return { "source": s.start, "column": s.end, "relation": s.relationship }
    })
}

function normalize_query_results(properties: any){
    const normalized_properties: any = {}
    Object.entries(properties)?.forEach(([key, value]: [string, any]) => {
        if (value?.__isInteger__) {
            value = value.low
        }
        normalized_properties[key] = value
    });
    return normalized_properties;
}

function format_query_result(properties: any){
    const formated_properties: any = {}
    Object.entries(properties)?.forEach(([key, value]: [string, any]) => {
        if (!isValidElement(value) && typeof (value) != 'string') {
            try{ value = JSON.stringify(value) } catch(e) {}
        }
        formated_properties[key] = value
    });
    return formated_properties;
}

export function convertTableFieldsQuery(properties: any) {
    const normalized_properties = normalize_query_results(properties);
    let type = `${normalized_properties?.labels[0]?.toLowerCase()}s`;
    if (type === 'inputs') type = `${type}/view`;
    else if (type === 'lookups') type = 'outputs';
    normalized_properties.id = React.createElement('a', {
        href: `https://app.upsolver.com/${type}/${normalized_properties.id}`,
        target: '_blank'
      }, normalized_properties.id);
    return format_query_result(normalized_properties);
} 

export function convertColumnFieldsQuery(properties: any) {
    const normalized_properties = normalize_query_results(properties);
    let type = `${normalized_properties?.source_labels[0]?.toLowerCase()}s`;
    if (type === 'inputs') type = `${type}/view`;
    else if (type === 'lookups') type = 'outputs';
    const sourceId = normalized_properties.source_id
    normalized_properties.source_id = React.createElement('a', {
        href: `https://app.upsolver.com/${type}/${sourceId}`,
        target: '_blank'
      }, sourceId);
    const id = normalized_properties.source_identity;
    type = normalized_properties.source_labels
    normalized_properties.source = React.createElement('a', {
        href: `/tables/${type}/${id}`,
        target: '_blank'
      }, normalized_properties.source);
    return format_query_result(normalized_properties);
} 

export function convertTableSourcesQuery(direction: boolean, records: any) {
    if (records && records.length > 0) {
        const seenSources = new Set();
        return records.map((record: any) => {
            const path = record.get('p');
            const segment = path.segments[0];
            const source = direction ? segment.start : segment.end;
            const sourceProperties = source.properties;
            const sourceIdentity = source.identity.low;
            const relationship = segment.relationship;
            const relationshipProperties = relationship.properties;
            const name = sourceProperties.name;
            const labels = source.labels;
            const link = `/tables/${labels.join('/')}/${sourceIdentity}`;
            const time_offset = relationshipProperties?.time_offset?.low ?? 0;
            const output_interval = sourceProperties?.output_interval?.low ?? 0;

            if (!seenSources.has(sourceIdentity)) {
                seenSources.add(sourceIdentity);
                return {
                    Level: path.length,
                    Source: name,
                    type: labels.join(', '),
                    Delay: output_interval,
                    "Time Wrap": time_offset,
                    link: link
                };
            }
            return null;
        }).filter((record: null) => record !== null); // Filter out null values
    }
    return [];
}

export function convertTableFields(records: any) {
    if (records && records.length > 0) {
        return records?.filter((record: any) => (record.get('p')?.end?.labels?.indexOf('Field') > -1))?.map((record: any) => {
            const path = record.get('p');
            const field = path.end;
            const labels = field.labels;
            const fieldType = field.properties.field_type;
            const fieldIdentity = field.identity.low;
            const link = `/columns/${labels.join('/')}/${fieldIdentity}`;
            let displayName = field.properties.display_name;
            if (displayName.__isInteger__) {
                displayName = displayName.low;
            }
            else if (typeof (displayName) != 'string') {
                displayName = JSON.stringify(displayName);
            }
            const result = {
                column: displayName, type: fieldType, labels: labels.join(', '), link: link
            }
            return (result);
        });
    }
}

export function convertColumnsQuery(records: any) {
    if (records && records.length > 0) {
        return records?.map((record: any) => {
            const path = record.get('p');
            const segment = path.segments[0]
            const source = segment.start;
            const sourceLabels = source.labels;
            const field = segment.end;
            const labels = field.labels;
            const fieldIdentity = field.identity.low;
            const relationship = segment.relationship
            const relationshipProperties = relationship.properties;
            const link = `/columns/${labels.join('/')}/${fieldIdentity}`;
            const time_offset = relationshipProperties?.time_offset?.low ?? 0
            const output_interval = source.properties?.output_interval?.low ?? 0
            return {
                'source': source.properties.name,
                'Source Label': sourceLabels.join(', '),
                'column_name': field.properties.name,
                'label': labels.join(', '),
                'column_type': field.properties.field_type,
                'link': link,
                'time_offset': time_offset,
                'output_interval': output_interval
            }
        });
    }
}

export function convertColumnsJsonTree(records: any) {
    if (records && records.length > 0) {
        return records?.map((row: any) => row['_fields'].map((obj: any) => {
            const value = typeof (obj[0].value) == 'object' ? JSON.stringify(obj[0].value) : obj[0].value;
            const key = obj[0].key
            return ({ [key]: value })
        }))[0];
    }
}