function getTable() {
    const query = `
        match p=(e) where ID(e) = $id return p
        union
        match p=(e)-[r:owns_field]-(f:Field) where ID(e) = $id 
        return p
        order by labels(f)
    `;
    console.log('getTable: ', query);
    return query;
}

function getColumnSankey({ direction, length, nodeLimit }: { direction: boolean, length: number, nodeLimit?: number }) {
    let query = '';
    const LIMIT = nodeLimit ? `LIMIT ${nodeLimit}` : '';

    if (direction) {
        query = `MATCH p=(source)-[:owns_field]->(f1:Field)-[r1:alias_from|originted_from|aggregated_from|calculated_from*1..${length}]->(f2:Field)<-[r2:owns_field]-(target)
        WHERE source <> target and ID(f1) = $id
        return p
        order by length(p)
        ${LIMIT}`
    }
    else{
        query = `MATCH p=(source)-[:owns_field]->(f1:Field)-[r1:alias_of|origin_of|aggregated_to|in_calculation_with*1..${length}]->(f2:Field)<-[r2:owns_field]-(target)
        WHERE source <> target and ID(f1) = $id
        return p
        order by length(p)
        ${LIMIT}`
    }

    console.log('getColumnSankey: ', query);
    return query;
}

function getColumnsSourcesQuery({ direction, length, nodeLimit }: { direction: boolean, length: number, nodeLimit?: number }) { 
    const LIMIT = nodeLimit ? `LIMIT ${nodeLimit}` : '';
    let query = null;
    if (direction) {
        query = `MATCH (root)-[root_owns_field:owns_field]->(root_field:Field) where ID(root_field) = $id
        MATCH p=(source)-[source_owns_field:owns_field]->(source_field:Field)-[r1:alias_of|origin_of|aggregated_to|in_calculation_with*1..${length}]->(root_field)   
        return p
        order by length(p)
        ${LIMIT}`
    }
    else{
        query = `MATCH (root)-[root_owns_field:owns_field]->(root_field:Field) where ID(root_field) = $id
        MATCH p=(source)-[source_owns_field:owns_field]->(source_field:Field)-[r1:alias_from|originted_from|aggregated_from|calculated_from*1..${length}]->(root_field)   
        return p
        order by length(p)
        ${LIMIT}`
    }     
    console.log('getColumnsSourcesQuery: ', query);
    return query;
}

function getTableGraph({ id, direction, length, nodeLimit }: { id: number, direction: boolean, length: number, nodeLimit?: number }) {
    const l = direction ? '-' : '<-';
    const r = direction ? '->' : '-';
    const LIMIT = nodeLimit ? `LIMIT ${nodeLimit}` : ''
    const query = `
    match p=(e)${l}[r:source_of|enriches*1..${length}]${r}(l) where (e:Input or e:Lookup or e:Reference or e:Output) and ID(l) = ${id} 
    return p
    order by length(p)
    ${LIMIT}`;
    console.log('getTableGraph: ', query);
    return query;
}

function getTableData() {
    const query = `match (l) where ID(id) = $id return l.all_references, l.display_name, l.id, l.inputs_ids, l.name, l.template_type;`;
    console.log('getTableData: ', query);
    return query;
}

function getTableFields() {
    const query = `match (i)-[r:owns_field]-(f:Field) where ID(i) = $id return f.name, f.field_type;`;
    console.log('getTableFields: ', query);
    return query;
}

function getTableSankey({ direction, length, nodeLimit }: { direction: boolean, length: number, nodeLimit?: number }) {
    let query = '';
    const LIMIT = nodeLimit ? `LIMIT ${nodeLimit}` : '';

    if (direction) {
        query = `
        match p=(source)-[r:source_of|enriches*1..${length}]->(target) where (source:Input or source:Reference or source:Lookup or source:Output) and ID(target) = $id
        return p
        order by length(p)
        ${LIMIT}`
    }
    else{
        query = `
        match p=(source)-[r:source_of|enriches*1..${length}]->(target) where (target:Input or source:Reference or target:Lookup or target:Output) and ID(source) = $id
        return p
        order by length(p)
        ${LIMIT}`
    }
    console.log('getTableSankey: ', query);
    return query;
}

function getTableSourcesQuery({ direction, length, nodeLimit }: { direction: boolean, length: number, nodeLimit?: number }) {
    const LIMIT = nodeLimit ? `LIMIT ${nodeLimit}` : '';
    let query = null;

    if (direction) {
        // Downstream: source -> target
        query = `
        MATCH p=(source)-[r:source_of|enriches*1..${length}]->(target)
        WHERE (source:Input OR source:Reference OR source:Lookup OR source:Output) AND ID(target) = $id
        WITH DISTINCT source, target, p, length(p) AS distance
        ORDER BY distance, 
            CASE 
                WHEN 'Input' IN LABELS(source) THEN 0 
                WHEN 'Output' IN LABELS(source) THEN 1 
                ELSE 2 
            END
        RETURN p
        ${LIMIT}`;
    } else {
        // Upstream: target -> source
        query = `
        match p=(source)-[r:source_of|enriches*1..${length}]->(target)
        where (target:Input or source:Reference or target:Lookup or target:Output) and ID(source) = $id
        WITH DISTINCT source, target, p, length(p) AS distance
        ORDER BY distance, 
            CASE 
                WHEN 'Input' IN LABELS(target) THEN 0 
                WHEN 'Output' IN LABELS(target) THEN 1 
                ELSE 2 
            END
        RETURN p
        ${LIMIT}`;
    }

    console.log('getTableSourcesQuery: ', query);
    return query;
}

function getGraphTable() {
    const query = `
    match (o)-[r1:source_of|join_with]-(e) where ID(e) = $id and (s1:UpsolverInput or o:Input or o:Lookup or source:Reference)
    return *`;
    console.log('getGraphTable: ', query);
    return query;
}
 
function getColumnProperty() {
    const query = `MATCH (s)-[:owns_field]-(f:Field) where ID(f) = $id return { 
        name: f.name,
        field_type: f.field_type,
        source: s.name,
        source_labels: Labels(s),
        source_id: s.id,
        source_identity: ID(s),
        max_date:f.last_seen,
        min_date:f.first_seen,
        distribution: f.distribution,
        aproxSize: f.total_rows,
        density: f.fill_rate,
        distinct_values: f.distinct_value_count
        } as result`

    console.log('getColumnProperty: ', query);
    return query;
}

function getJsonTreeColumnsQuery() {
    const query = `
    match (entity)-[owns_query_field:owns_field]->(query_field:Field) 
          where ID(query_field)=$id
          optional match (query_field)-[query_field_origin_fields:calculated_from|originted_from|alias_of]->(dsl_fields:Field)
          optional match (dsl_fields:Field)-[query_field_origin_fields_2:calculated_from|originted_from*1..2]->(dsl2_fields:Field)
          optional match (source)-[owns_source_fields:owns_field]->(dsl2_fields)
          where (source:Input or source:Lookup or source:Reference)
    return distinct collect({ key: query_field.name, value: query_field.dsl }) as Result`;
    console.log('getJsonTreeColumnsQuery: ', query);
    return query;
}

export {
    getTableFields, getTableSourcesQuery, getTableGraph, getTableData,
    getGraphTable, getTable, getColumnsSourcesQuery, getJsonTreeColumnsQuery, getTableSankey, getColumnSankey,
    getColumnProperty
};