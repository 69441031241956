

import { } from "d3-array";
import { } from "d3-axis";
import { select } from "d3-selection";
import { } from "d3-interpolate";
import './SankeyDiagram.css';
import { sankeyLinkHorizontal as d3sankeyLinkHorizontal } from "d3-sankey";
import { wrap } from "../../d3-lib/utils";

export const SankeyDraw = (width, height, svg, params, sankeyAlign, edgeColor, direction) => {
    svg.clear();

    const { data, sankey, color, format } = params;
  
    const { nodes, links } = sankey(sankeyAlign, data);
  
    const strokeOpacity = 0.2

    let node = svg
      .append("g")
      .selectAll("rect")
      .data(nodes)
      .join("rect")
      .attr("x", (d) => d.x0)
      .attr("y", (d) => d.y0)
      .attr("height", (d) => d.y1 - d.y0)
      .attr("width", (d) => d.x1 - d.x0)
      .attr("fill", color)
      .style("stroke", "white")
      .style("stroke-width", 0.5)
      .append("title")
      .text((d) => {
        return `${d.name}\n(${d.type})`
      });
  
    const link = svg
      .append("g")
      .attr("fill", "none")
      .attr("stroke-opacity", strokeOpacity)
      .selectAll("g")
      .data(links)
      .join("g")
      .style("mix-blend-mode", "multiply");
    
    link
      .append("title")
      .append("xhtml:body")
      .style("font", "14px 'Helvetica Neue'")
      .html((d) => `        
          <h1>source: </h1><p>${d.source.name}</p>
          <h1>source_id: </h1><p>${d.source.id}</p>
          <h1>type: </h1><p>${d.type}</p>
          <h1>target: </h1><p>${d.target.name}</p>        
          <h1>target_id: </h1><p>${d.target.id}</p>        
      `);
      
    if (edgeColor === "path") {
      const gradient = link
        .append("linearGradient")
        .attr("id", (d, i) => (d.identity = `link-${i}`))
        .attr("gradientUnits", "userSpaceOnUse")
        .attr("x1", (d) => d.source.x1)
        .attr("x2", (d) => d.target.x0);
  
      gradient
        .append("stop")
        .attr("offset", "0%")
        .attr("stop-color", (d) => color(d.source));
  
      gradient
        .append("stop")
        .attr("offset", "100%")
        .attr("stop-color", (d) => color(d.target));
    }
  
    link
      .append("path")
      .attr("class", "link")
      .attr("d", d3sankeyLinkHorizontal())
      .attr("stroke", (d) =>
        edgeColor === "none"
          ? "#aaa"
          : edgeColor === "path"
            ? `url(#${d.identity})`
            : edgeColor === "input"
              ? color(d.source)
              : color(d.target)
      )
      .attr("stroke-width", (d) => Math.max(1, d.width))
      .on("mouseover", function (d) {
        select(this).style("stroke-opacity", 0.5);
      })
      .on("mouseout", function (d) {
        select(this).style("stroke-opacity", strokeOpacity);
      });
     
    svg
      .append("g")
      .attr("font-family", "sans-serif")
      .style("font-size", "14px")
      .style("fill", "white")
      .selectAll("text")
      .data(nodes)
      .join("text")
      .attr("x", (d) => (d.x0 < width / 2 ? d.x1 + 6 : d.x0 - 6))
      .attr("y", (d) => (d.y1 + d.y0) / 2)
      .attr("dy", "0.35em")
      .attr("text-anchor", (d) => (d.x0 < width / 2 ? "start" : "end"))
      .text((d) => `${d.name} (${d.type})`)
      .call(wrap, 250);
  };