import styled from 'styled-components';
import {Input} from 'semantic-ui-react';

export const StyledDiv = styled.div`
  display: grid;
  justify-content: center;
  margin: 4rem 4rem;
`;

export const StyledInput = styled(Input)`
    width: 25vw;
`;

export const StyledTableDiv = styled.div`
  margin: auto;
  width: 60%;
`;