import React from 'react'


export const SankyContext= React.createContext<{
  direction: boolean,  
  setDirection?: React.Dispatch<React.SetStateAction<boolean>>,
  length: number,
  setLength?: React.Dispatch<React.SetStateAction<number>>,
  nodeLimit?: number,
  setNodeLimit?: React.Dispatch<React.SetStateAction<number>>,
  colorBy:number,
  setColorBy?: React.Dispatch<React.SetStateAction<number>>,
  aligned:number,
  setAligned?: React.Dispatch<React.SetStateAction<number>>,
  visible:boolean,
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>
} >({direction: true, length: 5, nodeLimit: 1500, colorBy: 2, aligned: 2, visible: true});

export function SankyProvider({children}:{children: any}) {
  const defaultDirection = true
  const defaultNodeLimit = 1500
  const defaultLengh = 5
  const defaultColorBy = 2
  const defaultAlign = 3
  const defaultVisible = false
  const [direction, setDirection] = React.useState(defaultDirection);
  const [nodeLimit, setNodeLimit] = React.useState(defaultNodeLimit);
  const [length, setLength] = React.useState(defaultLengh);
  const [colorBy, setColorBy] = React.useState(defaultColorBy);
  const [aligned, setAligned] = React.useState(defaultAlign);
  const [visible, setVisible] = React.useState(defaultVisible);
  return (
    <SankyContext.Provider value={{direction, setDirection, length, setLength, nodeLimit, setNodeLimit, colorBy, setColorBy, aligned, setAligned, visible, setVisible }}>
      {children}
    </SankyContext.Provider>
  )
}
