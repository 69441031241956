import { debug } from 'console';
import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { TableStyle } from '../Style';

export default function SearchResults({
    headersNames,
    loading,
    error,
    records
}: { headersNames: string[], loading: any, error: any, records: any }) {

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
    if (!records?.length) return <p>No data</p>;
    const nodes = records.map((v:any)=>v._fields[0])
    return (
        <TableStyle celled striped selectable>
            <Table.Header>
                <Table.Row>
                    {headersNames.map(name => {
                        return (<Table.HeaderCell>{name}</Table.HeaderCell>)
                    })}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {nodes.map((node: any) => {
                    return (
                            <Table.Row onClick={() => {
                                const type = node.labels.includes('Field') ? 'columns' : 'tables';                                
                                const link = `${type}/${node.labels.join('/')}/${node.identity}`
                                window.open(link, '_blank');
                            }}>
                            <Table.Cell>{node.labels.join(',')}</Table.Cell>
                            <Table.Cell>{node.properties.name}</Table.Cell>
                        </Table.Row>
                    )
                    }
                )}
            </Table.Body>
        </TableStyle>
    )
}