import styled from 'styled-components';
import { Radio } from 'semantic-ui-react';

 
export const RadioStyle = styled(Radio)` 
 
  &&& input[type="radio"]:checked + label {
    color: white !important;
  }
  &&& input[type="radio"]:not(:checked) + label {
    color: white !important;
  }
` 