import React from 'react';
import Sankey from "../../Components/Sankey/SankeyComponent";
import SankeyFilter from '../../Components/Sankey/SankeyFilterComponent';
import TableComponent from '../../Components/Table';

import { Grid, Container, Header, Table, Icon, Divider, } from 'semantic-ui-react'
import { useParams } from "react-router-dom";
import { useReadCypher } from "use-neo4j";
import { getColumnProperty, getColumnsSourcesQuery, getColumnSankey } from "../../db/Queries";
import { convertColumnFieldsQuery, convertColumnsQuery } from "../../Components/Table/RecordsFormatResolve";
import { getCypherSankey } from '../../Components/Sankey/SankeyResolve';
import { SankeyDraw } from '../../Components/Sankey/SankeyColumnDraw';
import { TableProperties } from "../../Components/Style";
import { SankyContext } from '../../Components/Sankey/SankyProvider';

export default function ColumnsPage() {
    const params: any = useParams();
    const { direction } = React.useContext(SankyContext)

    const paramsList = params['*'].split('/');
    const idKey = paramsList.length - 1;
    const columnId = parseInt(paramsList[idKey] || '');
    const slugName = (paramsList.splice(0, idKey) || '').join(', ');
    const queryParams = { id: columnId }
    const query = getColumnProperty()

    const { loading, error, records } = useReadCypher(query, queryParams)

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
    if (!records?.length) return <p>No data</p>;

    const record: any = records[0];
    const column = convertColumnFieldsQuery(record.get('result'))
    const columnName = `${column.name}.${column.field_type}`

    return (
        <SankeyFilter>
            <Container>
                <Header as='h2' icon inverted textAlign='center'>
                    <Icon name='columns' />{slugName} {columnName}
                    <Header.Subheader>
                        field discription goes here
                    </Header.Subheader>
                </Header>
                <Divider />
                <Grid.Row columns='equal'>
                    <Grid.Column>
                        <Header inverted as='h2' textAlign='center'> Properties </Header>
                        <TableProperties columns={2} celled striped selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Key</Table.HeaderCell>
                                    <Table.HeaderCell>Value</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row key='name'>
                                    <Table.Cell>name</Table.Cell>
                                    <Table.Cell>{column.name}</Table.Cell>
                                </Table.Row>

                                <Table.Row key='field_type'>
                                    <Table.Cell>field_type</Table.Cell>
                                    <Table.Cell>{column.field_type}</Table.Cell>
                                </Table.Row>

                                <Table.Row key='source'>
                                    <Table.Cell>source</Table.Cell>
                                    <Table.Cell>{column.source}</Table.Cell>
                                </Table.Row>

                                <Table.Row key='source_id'>
                                    <Table.Cell>source id</Table.Cell>
                                    <Table.Cell>{column.source_id}</Table.Cell>
                                </Table.Row>

                                <Table.Row key='source_labels'>
                                    <Table.Cell>source labels</Table.Cell>
                                    <Table.Cell>{column.source_labels}</Table.Cell>
                                </Table.Row>

                                <Table.Row key='min_date'>
                                    <Table.Cell>min date</Table.Cell>
                                    <Table.Cell>{!column.min_date ? new Date(column.min_date)?.toLocaleString() : ''}</Table.Cell>
                                </Table.Row>

                                <Table.Row key='max_date'>
                                    <Table.Cell>max date</Table.Cell>
                                    <Table.Cell>{!column.max_date ? new Date(column.max_date)?.toLocaleString() : ''}</Table.Cell>
                                </Table.Row>
                                <Table.Row key='density'>
                                    <Table.Cell>density</Table.Cell>
                                    <Table.Cell>{column.density}</Table.Cell>
                                </Table.Row>

                                <Table.Row key='distinct_values'>
                                    <Table.Cell>distinct_values</Table.Cell>
                                    <Table.Cell>{column.distinct_values}</Table.Cell>
                                </Table.Row>

                                <Table.Row key='distribution'>
                                    <Table.Cell>distribution</Table.Cell>
                                    <Table.Cell>{column.distribution}</Table.Cell>
                                </Table.Row>
 
                                <Table.Row key='aproxSize'>
                                    <Table.Cell>aproxSize</Table.Cell>
                                    <Table.Cell>{column.aproxSize}</Table.Cell>
                                </Table.Row>

                            </Table.Body>
                        </TableProperties>
                    </Grid.Column>
                </Grid.Row>

                
                <Header as='h2' inverted textAlign='center'> {(direction ? 'Upstream' : 'Downstream')} </Header>
                <Grid columns='equal' divided='vertically'>
                    <Grid.Row columns='equal'>
                        <Grid.Column>
                            <TableComponent tableQuery={getColumnsSourcesQuery}
                                queryParams={queryParams}
                                headersNames={['Source', 'Source Label', 'Name', 'Label', 'Type', 'Delay', 'Time Wrap']}
                                resolver={convertColumnsQuery}
                            />
                        </Grid.Column>
                    </Grid.Row>

                </Grid>
            </Container>
            <Grid>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Header inverted as='h2' textAlign='center'>
                            Sankey Diagram
                        </Header>
                        <Divider clearing />
                        <Sankey cypherQueryResolver={getColumnSankey} queryParams={queryParams} dataResolver={getCypherSankey} sankeyDraw={SankeyDraw} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </SankeyFilter>
    )
}