import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import SearchPage from './views/Search/Search';
import ColumnsPage from './views/Columns/Columns';
import TablesPage from './views/Table/Tables';
import { SankyProvider } from './Components/Sankey/SankyProvider';
import SankeyFilter from './Components/Sankey/SankeyFilterComponent';

function App() {
    return (
        <SankyProvider>
           
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<SearchPage />} />
                    <Route path="/search" element={<SearchPage />} />
                    <Route path="/columns/*" element={<ColumnsPage />} />
                    <Route path="/tables/*" element={<TablesPage />} />
                </Routes>
            </BrowserRouter>
        </SankyProvider>
    );
}

export default App;
