import React, { ReactNode } from 'react'
import { Button, Grid, Input, Menu, Sidebar } from 'semantic-ui-react';
import { RadioStyle as Radio } from  './Style';
import { SankyContext } from "./SankyProvider";

export default function SankeyFilter({ children }: { children: ReactNode }) {
  const { direction, setDirection, length, setLength, visible, setVisible } = React.useContext(SankyContext)

  const handleChange = (event: any, { checked }: any) => {
    if (setDirection) setDirection(checked);
  };

  const handleLengthChange = (event: any, data: any) => {
    if (setLength) setLength(parseInt(data.value));
  }

  const handleSideBarState = (event: any, data: any) => {
    if (setVisible) setVisible(!visible)
  };

  return (
    <>
      <Grid columns={1}>
        <Grid.Column>
          <Button
            size="big"
            icon={visible ? 'close' : 'bars inverted'}
            onClick={handleSideBarState}
            style={{
              margin: '10px', // Set padding to 10px
            }}
          />
        </Grid.Column>
        <Grid.Column>
          <Sidebar.Pushable>
            <Sidebar
              as={Menu}
              animation='overlay'
              icon='labeled'
              inverted
              onHide={() => setVisible ? setVisible(false) : null}
              vertical
              visible={visible}
              width='wide'>
              <Menu.Item as='a'>
                <Radio
                  slider
                  checkbox
                  label={direction === true ? 'upstream' : 'downstream'}
                  name='direction'
                  checked={direction === true}
                  onChange={handleChange} />
              </Menu.Item>
              <Menu.Item>
                <label>cypher length</label>
                <Input name="length" type="range" min="1" max="150" step="1" value={length} onChange={handleLengthChange}  />
                <span className="slider-value">{length}</span>
              </Menu.Item>
            </Sidebar>
            <Sidebar.Pusher dimmed={visible}>
              {children}
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </Grid.Column>
      </Grid>
    </>
  )
}