import React from "react";
import { schemeCategory10 as d3schemeCategory10 } from "d3-scale-chromatic";
import { scaleOrdinal as d3scaleOrdinal } from "d3-scale";

import { sankey as d3sankey } from "d3-sankey";

import {
  useSvg,
  makeSvgInit,
  callIfReady,
} from "../../d3-lib";

const ratio = window.innerHeight / window.innerWidth 
const width = window.innerWidth * 0.85
const height = ratio * width

const init = makeSvgInit({
  width: width,
  height: height,
});
 
const computeParams = (links, nodes) => {
  const data = {
    nodes: nodes.map((node) => {
      return ({ name: node.display_name, identity: node.identity, id: node.id, type: node.type, dsl: node.dsl });
    }),
    links: links.map((l) => {
      return {
        source: l.source.identity,
        target: l.target.identity,        
        type: l.type,
        value: l.value
      };
    }), units: "TWh"
  };
  
  const color0 = d3scaleOrdinal(d3schemeCategory10);
  const color = (d) => color0(d.identity === undefined ? d.name : d.identity);

  const format = null;
 
  const sankey0 = (sankeyAlign) =>
    d3sankey()
      .nodeId((d) => {
        return d.identity;
      })
      .nodeAlign(sankeyAlign)
      .nodeWidth(15)
      .nodePadding(10)
      .extent([
        [1, 5],
        [width - 1, height - 5],
      ]);

  const sankey = (sankeyAlign, { nodes, links }) =>
    sankey0(sankeyAlign)({
      nodes: nodes.map((d) => {
        return Object.assign({}, d);
      }),
      links: links.map((d) => {
        return Object.assign({}, d);
      }),
    });

  return { data, sankey, color, format };
};

const SankeyDiagram = (props) => {
  const [ container, svg ] = useSvg(init);
  const { draw, aligned, colorBy } = props;
  const { links, nodes } = props.reponse ?? {};

  const params = React.useMemo(() => callIfReady(computeParams, links, nodes), [
    links,
    nodes
  ]);

  React.useEffect(() => {
    if (svg && params) {
      if ((params.data.links.length) > 0 && (params.data.nodes.length))   {
          draw(width, height, svg, params, aligned, colorBy);
      }
    }
  }, [svg, params]);

  return (
    <React.Fragment>
      <div 
      ref={container}></div>
    </React.Fragment>
  );
};

export default SankeyDiagram;
